<template>
  <div>
    <ed-log-email  :key="$root.$session.versao" />
  </div>
</template>

<script>
import EdLogEmail from "@/components/common/log/email/index";
export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdLogEmail },
  mounted() {},
  beforeDestroy() {},
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
